import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken, getStandardAuthHeaders } from "./User";

export const getRequestsForService = (accountId, service, limit, onSuccess, onFailure) => {
    axios.get(`${API_HOST}/notics-tracer/v1/traces/service/${service}?limit=${limit}&accountId=${accountId}`, getStandardAuthHeaders() )
        .then(function (response) {
          // handle success
          onSuccess(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          onFailure(error);
        })
        .then(function () {
          // always executed
        });
}

export const getErrorRequestsForService = (accountId, service, start, end, onSuccess, onFailure) => {
  axios.get(`${API_HOST}/notics-tracer/v1/traces/service/${service}/errors?start=${start}&end=${end}&accountId=${accountId}`, getStandardAuthHeaders() )
      .then(function (response) {
        // handle success
        onSuccess(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        onFailure(error);
      })
      .then(function () {
        // always executed
      });
}