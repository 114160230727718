import { useState } from "react";

export const isDarkMode = () => {
    return (localStorage.getItem('darkMode') || 'false') == 'true';
}

export const setIsDarkMode = (v) => {
    localStorage.setItem('darkMode', v);
}

const lightMode = {
    background: "#FFFFFF",
    color: "#000000",
    cardColor: "#FFFFFF"
}

const darkMode = {
    background: "#01122e",
    color: "#FFFFFF",
    cardColor: "#0b2b5e"
}

export const getBackground = () => isDarkMode() ? darkMode.background : lightMode.background;
export const getColor = () => isDarkMode() ? darkMode.color : lightMode.color;
export const getCardColor = () => isDarkMode() ? darkMode.cardColor : lightMode.cardColor;

export const useTheme = () => {

    const [darkMode, setDarkMode] = useState(false);

}