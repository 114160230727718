import axios from "axios";
import { API_HOST } from "./API";
import { getStandardAuthHeaders } from "./User";

// export const createApiToken = (scopes, onSuccess, onFailure) => {
//     axios.post(`${API_HOST}/core-services/v1/api-tokens/create`,
//     {
//         scopes: scopes
//     },
//     {
//         getStandardAuthHeaders() 
//     }
//     )
//         .then(function (response) {
//             // handle success
//             onSuccess(response);
//             // let t = apiTokens;
//             // t.push(response.data);
//             // setApiTokens(t);
//         })
//         .catch(function (error) {
//             // handle error
//             console.log(error);
//             onFailure(error)
//         })
//         .then(function () {
//             // always executed
//         }
//     );
// }

export const getApiTokens = (accountId, onSuccess, onFailure) => {
    axios.get(`${API_HOST}/core-services/v1/api-tokens/${accountId}`, getStandardAuthHeaders() )
        .then(function (response) {
          // handle success
          onSuccess(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          onFailure(error);
        })
        .then(function () {
          // always executed
        });
}