import { Header } from "./Header";
import { NavigationSidebar } from "./NavigationSidebar";
import { useParams } from "react-router-dom";

export const Page = (params) => {

    const { name } = params;
    const { accountId, service } = useParams();

    return (
        <div style={{
            height: "100vh",
            display: "flex",
            width: "100%",
            background: "#F7F8FC",
            fontFamily: 'Mulish'
        }}>
            <NavigationSidebar style={{
                background: "#363740",
                color: "#F7F8FC",
                width: "18%"
                }}
                items={[
                    {name: "Overview", url: ``},
                    {name: "Traces", url: removeTrailingSlash(`/test/${accountId}/traces/${service || ''}`)},
                    {name: "Insights", url: removeTrailingSlash(`/test/${accountId}/insights/${service || ''}`)},
                    {name: "Account", url: `/${accountId}/account`},
                ]}
            />
            <div style={{width: "80%", marginLeft: "2%", marginRight: "2%"}}>
                <Header pageName={name} />
                {params.children}
            </div>
        </div>
    );
}

function removeTrailingSlash(str) {
    return str.endsWith('/') ? str.slice(0, -1) : str;
  }