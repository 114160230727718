import * as React from 'react';
import { useState } from 'react';
import ReactJson from 'react-json-view'
import Chart from "react-apexcharts";
import { getBackground, getColor, isDarkMode } from './Theme';

export default function Timeline(params) {

  const [selectedDataPoint, setSelectedDataPoint] = useState({});

        if (Object.keys(params.input).length === 0) {
            return (
                <div id="chart">
                    Select a request to display the trace
                </div>
            );
        }
        console.log(params.input[0].correlationId);

        let dataPoints = [];

        const start = params.input[0].timeStart;

        const options =  {
          theme: {
            mode: isDarkMode() ? "dark" : "light"
          },
          chart: {
            events: {
              dataPointSelection: function(event, chartContext, config) {
                console.log("Selected event: ", dataPoints[config.dataPointIndex])
                setSelectedDataPoint(dataPoints[config.dataPointIndex]);
              }
            },
            background: getBackground()
          },
          plotOptions: {
              bar: {
                  horizontal: true,
              }
          },
          subtitle: {
            text: `${params.input[0].responseTime}ms [${params.input[0].statusCode}] ${params.input[0].httpMethod} ${params.input[0].url} (${params.input[0].correlationId})`,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '12px',
              fontWeight:  'normal',
              fontFamily:  undefined,
              //color:  '#9699a2'
            },
        },
          yaxis: {
            min: 0,
            max: params.input[0].timeEnd - start
          },
          xaxis: {
             type: 'datetime'
          },
          fill: {
            type: 'gradient',
            gradient: {
                  shade: 'light',
                  type: "vertical",
                  shadeIntensity: 0.25,
                  gradientToColors: undefined,
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [50, 0, 100, 100]
              }
          }
        };

        const seriesData = [];

        params.input.forEach(element => {
          let spanStart = element.timeStart - start;
          const spans = [{
            x: element.requestFunction,
            y: [spanStart, element.timeEnd - spanStart]
          }];
          
          element.context.forEach(e => {
              spans.push({
                  x: e.label,
                  y: [e.timeStart - start, e.timeEnd - start],
                  context: e.contextMap
              });
            });

          seriesData.push({
            name: element.requestFunction,
            data: spans
          });

          dataPoints = dataPoints.concat(spans);
        });        

        console.log("Selected Data Point: ", selectedDataPoint.context);
      return (
        <div id="chart" style={{background: getBackground(), color: getColor()}}>
          <Chart options={options} series={seriesData} type="rangeBar" height="350" />
          {Object.keys(((selectedDataPoint || {context: {}}).context || {})).length > 0 ? 
          <ReactJson 
            style={{textAlign: "start", fontSize: "100%", backgroundColor: getBackground()}} 
            src={selectedDataPoint.context} 
            theme={isDarkMode() ? "bright" :  "rjv-default"}
            displayDataTypes={false}
          /> : <div />}
        </div>
      );
  }