import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { getCardColor , getColor} from './Theme';
// import Tooltip from '@mui/material/Tooltip';
// import InfoIcon from '@mui/icons-material/Info';

export default function DataCard(props) {
  return (
    <Card className="App" style={{background: getCardColor()}} sx={{ width: 200, borderRadius: 5, marginInline: "2%", background: getCardColor(), color: getColor(), borderColor: getColor()}}>
      <CardContent style={{background: getCardColor()}}>
        {/* <Tooltip title="Add">
          <InfoIcon style={{ alignItems: "right" }}/>
        </Tooltip> */}
        <Typography variant="h5" component="div">
          <b>{props.value}</b> <small>{props.label}</small>
        </Typography>
        <Typography sx={{ mb: 1.5, color: getColor() }} color="text.secondary">
          {props.name}
        </Typography>
      </CardContent>
    </Card>
  );
}
