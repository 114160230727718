import { useEffect, useState } from 'react';
import * as React from 'react';

import * as axios from 'axios'; // https://github.com/axios/axios
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Timeline from '../components/Timeline.js';

import Navbar from '../components/navbar';
import { getBackground, getColor } from '../components/Theme';

// Select box
import Box from '@mui/material/Box';

import {
    useParams
  } from "react-router-dom";
import { API_HOST } from '../api/API.js';

export const RecentRequests = () => {

    let { service } = useParams();
  
    if (!service) {
      service = "business-management-api";
    }
  
    useEffect(() => {
        document.title = `${service} recent requests - Notics Tracer`;
      }, [service]);
  
    const [selectedRequest, setSelectedRequest] = useState({});
    const [requests, setRequests] = useState({});
  
    const requestListItem = Object.keys(requests).map((key) => {
      let value = requests[key];
      let element = value[0];
      let date = new Date(element.timeStart);
      let statusCodeColor = "green";
      if (element.statusCode > 499) {
        statusCodeColor = "red";
      } else if (element.statusCode > 299) {
        statusCodeColor = "yellow";
      }
     return (
      <ListItem onClick={()=> {
        setSelectedRequest(value);
        }}>
        <ListItemAvatar>
          <Avatar style={{backgroundColor: statusCodeColor}}>
            <b>{`${element.statusCode}`}</b>
            {/* <ImageIcon /> */}
          </Avatar>
        </ListItemAvatar>
        <ListItemText style={{color: getColor()}}
        secondaryTypographyProps={{style: {color: getColor()}}}
        primary={`${element.httpMethod} ${element.url}`} 
        secondary={`${element.responseTime}ms on ${date.toLocaleDateString()} @ ${date.toLocaleTimeString()}`} 
        />
      </ListItem>
            )
    });
  

     useEffect(() => {
      axios.get(`${API_HOST}/notics-tracer/v1/reporting/service/${service}?limit=5`)
      .then(function (response) {
        // handle success
        setRequests(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }, [service]);
  
    return (
      <div className="App">
      <Navbar />
  
      <Box style={{height: "20%", overflow: 'auto'}} sx={{height: "20%"}}>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: getBackground(), color: getColor(), height: "20%" }}>
        {requestListItem}
      </List>
      </Box>
  
        <div style={{ height: 1000, width: 1000 }}>
  
        <Timeline input={selectedRequest} />
  
        </div>
      </div>
    );
  }