import { useEffect, useState } from 'react';
import * as React from 'react';
import * as axios from 'axios'; // https://github.com/axios/axios

import DataCard  from "../components/DataCard";
import { useParams, useHistory } from 'react-router-dom';

import Chart from "react-apexcharts";

// Table stuff
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from '../components/navbar';
import { DateTimePicker } from 'react-rainbow-components';
import { getBackground, getColor, isDarkMode } from '../components/Theme';
import { API_HOST } from '../api/API';

export default function Insights() {

    const [insights, setInsights] = useState({});

    const [start, setStart] = useState(new Date(Date.now()-604800000));
    const [end, setEnd] = useState(new Date());

    let { accountId, service } = useParams();
    let history = useHistory();
  
    if (!service) {
      service = "business-management-api";
    }

    useEffect(() => {
      document.title = `${service} insights - Notics Tracer`;
    }, [service]);
  
     useEffect(() => {
      axios.get(`${API_HOST}/notics-tracer/v1/insights/service/${service}?start=${start.getTime()}&end=${end.getTime()}`)
      .then(function (response) {
        // handle success
        setInsights(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }, [service, start, end]);

    const responseTimeChartData = (insights.data || []).map(k => k.avgResponseTime);
    //const requestCountData = (insights.data || []).map(k => k.requestsCount);
    const requestCountData = (insights.data || []).map(k => (k.requestsCount));
    const chartLabels = (insights.data || []).map(k => k.date);
  
    return (
      <div className="App">
        <Navbar serviceSwitcherEnabled={true}/>
        <div style={{display: "flex", width: "50%", marginLeft: "25%", marginBottom: "2.5%"}} >
          <DateTimePicker 
            label="Start"
            value={start}
            onChange={setStart}
            style={{marginRight: "2%"}}
          />
          <DateTimePicker 
            label="End"
            value={end}
            onChange={setEnd}
          />
        </div>
        <div style={{display: "flex", marginLeft: "12.5%", width: "75%", justifyContent: "center", flexDirection: "row"}}>
            <DataCard value={insights.parentData ? insights.parentData.avgResponseTime : 0} label="ms" name="Avg Reponse Time" />
            <DataCard value={insights.parentData ?insights.parentData.requestsCount : 0} label="" name="Requests" />
            <DataCard value={(insights.parentData ? insights.parentData.availability : 0 || 0).toFixed(2)} label="%" name="Availability" />
        </div>
        <div>
          {insights.data ? 
          <Chart options={{
              theme: {
                mode: isDarkMode() ? "dark" : "light"
              },
              chart: {
                height: 350,
                type: 'area',
                background: getBackground()
              },
              dataLabels: {
                enabled: true
              },
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                type: 'category',
                categories: chartLabels
              },
              yaxis: {
                forceNiceScale: true
              }
            }} series={
              [{
              name: 'Response Time (ms)',
              data: responseTimeChartData
            }]} type="area" height="350" /> : <div/>}
        </div>
        <div>
          {insights.data ? 
          <Chart options={{
              theme: {
                mode: isDarkMode() ? "dark" : "light"
              },
              chart: {
                height: 350,
                type: 'line',
                background: getBackground()
              },
              dataLabels: {
                enabled: true
              },
              stroke: {
                curve: 'straight'
              },
              xaxis: {
                type: 'category',
                categories: chartLabels
              },
              // yaxis: {
              //   min: 0,
              //   max: 100
              // }
            }} series={
              [{
              name: 'Request Count',
              data: requestCountData,
            }]} type="area" height="350" /> : <div/>}
        </div>
        <div>
            <h2>Slowest Functions</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, background: getBackground(), color: getColor()}} aria-label="slowest functions">
                    <TableHead>
                    <TableRow>
                        <TableCell style={{color: getColor()}}><b>Request Function</b></TableCell>
                        <TableCell style={{color: getColor()}} align="right"><b>Avg Response Time</b></TableCell>
                        <TableCell style={{color: getColor()}} align="right"><b>Request Count</b></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {(insights.parentData ? insights.parentData.slowestFunctions : []).map((row) => (
                        <TableRow
                        key={row.requestFunction}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: getColor() }}
                        style={{color: getColor()}}
                        onClick={() => history.push(`/${accountId}/service/${service}/function/${row.requestFunction}`)}
                        >
                        <TableCell style={{color: getColor()}} component="th" scope="row">
                            {row.requestFunction}
                        </TableCell>
                        <TableCell style={{color: getColor()}} align="right">{(row.responseTime || 0).toFixed(0)} ms</TableCell>
                        <TableCell style={{color: getColor()}} align="right">{row.count}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
      </div>
    );
  }