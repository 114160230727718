import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

export const NavigationSidebar = (params) => {

    const { style, items, onChange } = params;

    return (
        <div style={{
            ...style,
            position: "relative"
        }}>
            
            <div style={{
                height: "10%",
                zIndex: 10,
                top: 0,
                right: 0,
            }}>
                <img 
                    src={"https://portal.notics.app/cdn/notics-logo.svg"} 
                    style={{
                        filter: "drop-shadow( 0px 0px 7px rgba(255, 255, 255, .9))",
                        marginTop: "-15%",
                        borderRadius: "50%",
                        zIndex: 10,
                        // position: "absolute",
                       
                        height: "150px"
                    }}
                />
            </div>
            {/* <Application theme={{rainbow: {palette: {brand: "#DDE2FF" }, tex}}}>
            <Sidebar style={{
                
            }}
             selectedItem={selectedItem} onSelect={(event, value) => {
                setSelectedItem(value); 
                if (onChange) {
                    onChange(value);
                }
                }} id="navigation-sidebar">
                {(items || []).map(i => {
                    console.log("Adding", i)
                    // style={{height: "4.5%", color: "white"}}
                    return <SidebarItem style={{color: "#FFFFFF"}} key={i.name} name={i.name} label={i.label} />
                })}
            </Sidebar>
            </Application> */}
            <Drawer
                    sx={{
                    width: style.width,
                    // marginTop: "10px",
                    bgcolor: style.background,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: style.width,
                        boxSizing: 'border-box',
                    },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                <img 
                    src={"https://portal.notics.app/cdn/notics-logo.svg"} 
                    style={{
                        marginTop: "-10%",
                        zIndex: 10,
                        // position: "absolute",
                        height: "150px",
                        background: style.background,
                        stroke: "#FFFFFF",
                        strokeWidth: "1px"
                    }}
                />   
                <List sx={{ bgcolor: style.background, height: "100vh"}}>
                    {(items || []).map(i => 
                    <ListItem button key={i.name}>
                        <Link to={i.url}>
                            <ListItemText primaryTypographyProps={{color: "#FFFFFF", fontSize: "16px", lineHeight: "20px", letterSpacing: "0.2px", fontFamily: 'Mulish'}} primary={i.name}/>
                        </Link>
                        </ListItem>)}
                </List>
            </Drawer>
        </div>
    );
}