import { useEffect, useState } from 'react';
import * as React from 'react';
import * as axios from 'axios'; // https://github.com/axios/axios
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Chart from "react-apexcharts";
import Timeline from '../components/Timeline';
import {
  useParams
} from "react-router-dom";
import Navbar from '../components/navbar';
import Box from '@mui/material/Box';
import FunctionSwitcher from '../components/navbar/FunctionSwitcher';
import { API_HOST } from '../api/API';
import { getBackground, getColor, isDarkMode } from '../components/Theme';


export default function FunctionRequests() {
  let { service, requestFunction } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  if (!service) {
    service = "business-management-api";
  }

  useEffect(() => {
      document.title = `${requestFunction} Requests - Notics Tracer`;
    }, [service]);

  const [selectedRequest, setSelectedRequest] = useState({});
  const [requests, setRequests] = useState({});
  const [logs, setLogs] = useState("");

  const requestListItem = Object.keys(requests).map((key) => {
    let value = requests[key];
    let element = value[0];
    let date = new Date(element.timeStart);
    let statusCodeColor = "green";
    if (element.statusCode > 499) {
      statusCodeColor = "red";
    } else if (element.statusCode > 299) {
      statusCodeColor = "yellow";
    }
   return (
    <ListItem key={element.correlationId} onClick={()=> {
      setSelectedRequest(value);
      setLogs("Loading...")
      console.log("Selected Request", value)
      }}>
      <ListItemAvatar>
        <Avatar style={{backgroundColor: statusCodeColor}}>
          <b>{`${element.statusCode}`}</b>
          {/* <ImageIcon /> */}
        </Avatar>
      </ListItemAvatar>
      <ListItemText 
      primary={`${element.httpMethod} ${element.url}`} 
      secondaryTypographyProps={{style: {color: getColor()}}}
      secondary={`${element.responseTime}ms on ${date.toLocaleDateString()} @ ${date.toLocaleTimeString()}`} 
      />
    </ListItem>)
  });

  let keys = Object.keys(requests);

  let requestData = [{name: "Total", data: []}];
  let labelsData = [];
  
  // Build list of times & labels
  keys.forEach((key) => {
    let value = requests[key];
    let element = value[0];
    let date = new Date(element.timeStart);
    requestData[0].data.push([element.timeStart, element.responseTime]);
    labelsData.push(date);
    element.context.forEach(c => {
      if (!requestData.find(e => e.name === c.label)) {
        requestData.push({name: c.label, data: []})
      }
    });
  });

  keys.forEach((key) => {
    let value = requests[key];
    let element = value[0];
    requestData.filter(e => e.name !== "Total").forEach(r => {
      let mappedValues = element.context.filter(e => r.name === e.label).map(a => a.duration);
      let value = (mappedValues.length > 0 ? mappedValues : [0]).reduce((a, b) => a + b);
      r.data.push([element.timeStart, value]);
    });
  });

   useEffect(() => {
    axios.get(`${API_HOST}/notics-tracer/v1/reporting/service/${service}/function/${requestFunction}?limit=100`)
    .then(function (response) {
      // handle success
      setRequests(response.data);
      console.log("Set requests to ", response.data)
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }, [service, requestFunction]);

  useEffect(() => {
    //http://localhost/v1/proxy/logs/transactions-web?start=1635767138650&end=1635767138749
    if (Object.keys(selectedRequest).length == 0) {
      return;
    }
    axios.get(`${API_HOST}/notics-tracer/v1/proxy/logs/${service}?start=${selectedRequest[0].timeStart}&end=${selectedRequest[0].timeEnd}`)
    .then(function (response) {
      // handle success
      //setRequests(response.data);
      setLogs(response.data);
      console.log("Set requests to ", response.data)
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }, [selectedRequest]);

  return (
    <div className="App" style={{height: "100vh", background: getBackground(), color: getColor()}}>
    <Navbar serviceSwitcherEnabled={false}/>
    <FunctionSwitcher />

    {requestData ? 
          <Chart options={{
              theme: {
                mode: isDarkMode() ? "dark" : "light"
              },
              chart: {
                height: 350,
                type: 'area',
                stacked: false
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight'
              },
              // fill: {
              //   type: 'gradient',
              //   gradient: {
              //     opacityFrom: 0.6,
              //     opacityTo: 0.8,
              //   }
              // },
              xaxis: {
                type: 'datetime'
              }
            }} series={requestData} type="area" height="50%" /> : <div/>}
      <div style={{display: "flex",  height: "40%", width: "100%", background: getBackground()}}>
        <Box sx={{overflow: 'scroll'}}>
          <List sx={{ width: '100%', bgcolor: getBackground(), color: getColor()}}>
            {requestListItem}
          </List>
        </Box>
        <div style={{width: "75%", textAlign: "left", overflow: "scroll",  background: getBackground()}}>
            <code style={{
              lineHeight: "2em"
            }}>{logs}</code>
        </div>
      </div>

      <div style={{ height: "100vh", width: "100%", background: getBackground() }}>

      <Timeline input={selectedRequest} />

      </div>
    </div>
  );
}
