import axios from "axios";
import { useEffect, useState } from "react";
import { Accordion, AccordionSection, Button, Column, MultiSelect, Option, ProgressBar, TableWithBrowserPagination } from "react-rainbow-components";
import { useParams } from "react-router-dom";
import { getAccountUsage } from "../api/AccountService";
import { API_HOST } from "../api/API";
import { getApiTokens } from "../api/TokensService";
import Navbar from "../components/navbar";
import { Page } from "../components/Page";
import { StyledCard } from "../components/StyledCard";
import { getBackground } from "../components/Theme";
import { TokenViewer } from "../components/TokenViewer";


export const Account = () => {

    // When writing to api, selectedScopes.map(s => s.name);
    const [selectedScopes, setSelectedScopes] = useState([]);
    const [apiTokens, setApiTokens] = useState([]);
    const [usage, setUsage] = useState(0);
    // TODO: Pull this from somewhere
    const [limit, setLimit] = useState(1000); 

    const { accountId } = useParams();

    useEffect(() => {
        document.title = `Account - Notics Tracer`;
      }, []);

    useEffect(() => {
        getApiTokens(accountId, (response) => {
            setApiTokens(response.data);
        }, () => {});

        getAccountUsage(accountId, (response) => {
            setUsage(response.data);
        }, () => {});
    
      }, []);

    const createApiToken = () => {
        axios.post(`${API_HOST}/core-services/v1/api-tokens/create`,
        {
            scopes: selectedScopes.map(s => s.name)
        })
            .then(function (response) {
                // handle success
                //setAvailableServices(response.data);
                let t = apiTokens;
                t.push(response.data);
                setApiTokens(t);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            }
        );
    }

    return (
        <Page name="Account">
            <StyledCard>
                <h1>Tokens</h1>
                <TableWithBrowserPagination pageSize={10} data={apiTokens} keyField="token" variant="listview">
                    <Column header="Token" field="token" component={TokenViewer} />
                    <Column cellAlignment="left" header="Created" field="createdAt" component={(c) => (<h1>{new Date(c.value).toLocaleString()}</h1>)} />
                </TableWithBrowserPagination>
                {apiTokens.map(t => {
                    return (
                        <div style={{display: 'flex', padding: "1%", fontSize: "1.5em"}}>
                            <p style={{padding: "1%"}}>{t.token}</p>
                            <p style={{padding: "1%"}}>{t.scopes}</p>
                            <p style={{padding: "1%"}}>{t.createdAt}</p>
                        </div>
                    );
                })}
                <Accordion>
                    <AccordionSection label="Create new Tokens">
                        <MultiSelect
                            id="multiselect-component-1"
                            label="Select the scopes this api key should have"
                            placeholder="Scopes"
                            style={{maxWidth: "60%"}}
                            //className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                            value={selectedScopes}
                            onChange={setSelectedScopes}
                            
                            // bottomHelpText="Select the scopes this api key should have"
                            //enableSearch
                            >
                                {/* icon={<DashboardIcon />} */}
                                <Option name="TRACE_WRITE" label="Tracer Write" />
                                <Option name="TRACE_READ" label="Tracer Read" />
                        </MultiSelect>
                        <Button style={{background: getBackground()}} onClick={createApiToken}>Create Api Token</Button>
                    </AccordionSection>
                </Accordion>
            </StyledCard>
            <StyledCard>
                <h1>Usage</h1>
                <div className="rainbow-p-around_x-large">
                    <div className="rainbow-align-content_space-between rainbow-p-bottom_x-small">
                        <span className="rainbow-font-size-text_medium rainbow-color_gray-4">
                            {usage} Traced Requests
                        </span>
                        <span aria-hidden="true">
                            <strong className="rainbow-font-size-text_medium rainbow-color_brand">
                                {((usage/limit)*100).toFixed(0)}% of limit
                            </strong>
                        </span>
                    </div>
                    <ProgressBar value={(usage/limit)*100} />
                </div>
            </StyledCard>
        </Page>
    );

    // return (
    //     <div style={{height: "300vh", background: getBackground()}}>
    //         <Navbar serviceSwitcherEnabled={false} />
    //         <MultiSelect
    //         id="multiselect-component-1"
    //         label="Select the scopes this api key should have"
    //         placeholder="Scopes"
    //         style={{maxWidth: "60%", height: "100vh", background: getBackground()}}
    //         //className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
    //         value={selectedScopes}
    //         onChange={setSelectedScopes}
            
    //         // bottomHelpText="Select the scopes this api key should have"
    //         //enableSearch
    //         >
    //              {/* icon={<DashboardIcon />} */}
    //             <Option name="TRACE_WRITE" label="Tracer Write" />
    //             <Option name="TRACE_READ" label="Tracer Read" />
    //             {/* <Option name="VALUES_WRITE" label="Values Write" />
    //             <Option name="VALUES_READ" label="Values Read" /> */}
    //         </MultiSelect>
    //         <Button style={{background: getBackground()}} onClick={createApiToken}>Create Api Token</Button>
    //         <div style={{background: getBackground()}}>
    //             Api Tokens:
    //             {apiTokens.map(t => {
    //                 return (
    //                     <div style={{display: 'flex', padding: "1%", fontSize: "1.5em"}}>
    //                         <p style={{padding: "1%"}}>{t.token}</p>
    //                         <p style={{padding: "1%"}}>{t.scopes}</p>
    //                         <p style={{padding: "1%"}}>{t.createdAt}</p>
    //                     </div>
    //                 );
    //             })}
    //         </div>
    //     </div>
    // );
}