import { Page } from "../components/Page"
import React from 'react';
import { TableWithBrowserPagination, Table, Column, Badge } from 'react-rainbow-components';
import { useState } from "react";
import { useEffect } from "react";
import { getCoreVitals, getRequestCountByDay } from "../api/StatsService";
import { Button } from "@mui/material";
import { SimpleChart } from "../components/SimpleChart";
import { StyledCard } from "../components/StyledCard";
import { NumberWithChange } from "../components/NumberWithChange";
import humanize from "humanize-plus";
import { useQuery } from "../utils/QueryParams";
import { useParams } from "react-router-dom";
import { getRequestsForService } from "../api/TracesService";
import Timeline from "../components/Timeline";
import ServiceSwitcher from "../components/navbar/ServiceSwitcher";


export const Traces = () => {

    const [traces, setTraces] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedTrace, setSelectedTrace] = useState({});

    // const query = useQuery();
    // console.log("Service: ", query.get("service"));

    const {accountId, service} =  useParams();

    const now = new Date();
    now.setDate(now.getDate() - 30);
    

    const [time, setTime] = useState(now.getTime());

    useEffect(() => {
        if (!service) {
            return;
        }
        setLoading(true);
        getRequestsForService(accountId, service, 100, (resp) => {setTraces(resp.data); setLoading(false);}, () => {});
    }, [accountId, service, time]);

    const TraceGraph = () => {
        if (Object.keys(selectedTrace) === 0) {
            return <div/>;
        }
        return <Timeline input={selectedTrace}/>
    }

    return (
        <Page name="Traces">
                <div className="rainbow-p-bottom_xx-large">
                    <StyledCard>
                        <ServiceSwitcher />
                    </StyledCard>
                    <StyledCard>
                        <TableWithBrowserPagination pageSize={10} isLoading={loading} data={Object.keys(traces).map(k => sortByKey(traces[k], "depth")[0])} keyField="correlationId" variant="listview">
                            {/* <Column header="Correlation Id" field="correlationId" /> */}
                            <Column cellAlignment="left" header="Timestamp" field="timeStart" component={(c) => (<h1>{new Date(c.value).toLocaleString()}</h1>)} />
                            <Column header="Function" field="requestFunction" component={(c) => <h1>{c.value.substring(c.value.lastIndexOf('.') + 1)}</h1>} />
                            <Column defaultWidth="75" header="Method" field="httpMethod" />
                            <Column header="Calling Source" field="callingSource" />
                            <Column defaultWidth="100" header="Time" field="responseTime" component={(c) => <h1>{c.value} ms</h1>} />
                            <Column defaultWidth="75" header="Status" field="statusCode" />
                            <Column defaultWidth="200" header="" field="correlationId" component={(c) => (selectedTrace.length > 0 ? selectedTrace[0].correlationId : "") === c.value ? <div /> :<Button style={{width: "100%"}} onClick={() => setSelectedTrace(traces[c.value])}>Show Trace</Button>} /> 
                            {/* <Column header="Response Time" field="avgResponseTime" component={(c) => <NumberWithChange value={`${c.value.toFixed(1)} ms`} change={c.row.avgResponseTimeChange.toFixed(1)} invertColors={true} />} />
                            <Column header="Availability" field="availability" component={(c) => <NumberWithChange value={`${c.value.toFixed(1)} %`} change={c.row.availabilityChange.toFixed(1)} />} />
                            <Column header="" component={(c) => <Button style={{width: "100%"}} onClick={() => console.log("Going to traces for ", c.value)}>See Traces</Button>} /> */}
                        </TableWithBrowserPagination>
                    </StyledCard>
                    <StyledCard>
                        <TraceGraph />
                    </StyledCard>
                </div>
        </Page>
    );
}

function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}