import React from 'react';
import * as axios from 'axios'; // https://github.com/axios/axios
import { useEffect, useState } from 'react';

// Select box
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { getBackground, getColor } from '../Theme';
import { API_HOST } from '../../api/API';
import { Picklist, Option, ButtonIcon } from 'react-rainbow-components';

export default function ServiceSwitcher() {

    const [availableServices, setAvailableServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    let location = useLocation();
  
    let { accountId, service } = useParams();
  
    const history = useHistory();
  
    useEffect(() => {
      axios.get(`${API_HOST}/notics-tracer/v1/reporting/available-services?accountId=${accountId}`)
      .then(function (response) {
        // handle success
        setAvailableServices(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        setIsLoading(false);
      });
  
    }, []);
  
    const servicesList = availableServices.map(service => {
      return( <Option key={service} name={service} label={service}/> );
    });

    return (
        <div>
            <Box sx={{ width: "25%", background: getBackground(), color: getColor(), borderColor: getColor()}} style={{}}>
            <Picklist
                            id="picklist-9"
                            // style={containerStyles}
                            onChange={(value) => history.push(service ? location.pathname.replace(service, value.name) : `${location.pathname}/${value.name}`)}
                            value={service}
                            label="Select Service"
                            isLoading={isLoading}
                            enableSearch={true}
                            placeholder={service}
                        >
                            {servicesList}
                        </Picklist>
            </Box>
        </div>
    );
}