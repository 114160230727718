import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, MultiSelect, Option } from "react-rainbow-components";
import { useHistory, useParams } from "react-router-dom";
import { getAvailableServices } from "../api/DashboardService";
import { getColor } from "../components/Theme";


export const Dashboard = () => {
    useEffect(() => {
        document.title = "Dashboard - Notics Tracer";
      }, []);

    const [services, setServices] = useState([]);

    let history = useHistory();

    let { accountId } = useParams();
    

    useEffect(() => {
        getAvailableServices(accountId, (availableServices) => {
            setServices(availableServices.data);
        }, (failure) => {

        })    
      }, []);

    return (
        //height: "100vh" <--- Key to fully covered background
        <div style={{height: "100vh", clear: "both"}}>
            {/* Add some title card about the account */}
            {/* Add usage data such as total count of request for the month */}
            {/* Add an empty state for  */}
            <div style={{display: "flex",  flexWrap: "wrap"}}>
                {services.map(s => {
                    return (
                        <Card style={{
                            margin: "2.5%",
                            fontSize: "2em",
                            textAlign: "center",
                            padding: "1%",
                            width: "15%",
                            borderColor: getColor(),
                            borderWidth: "1%",
                            font: "Roboto"
                        }}>
                            <h1>{s}</h1>
                            <Button onClick={() => history.push(`/${accountId}/service/${s}`)}>See Traces</Button>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
}