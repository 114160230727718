import axios from "axios";
import { API_HOST } from "./API";
import { getStandardAuthHeaders } from "./User";

export const getAccountUsage = (accountId, onSuccess, onFailure) => {
    axios.get(`${API_HOST}/notics-tracer/v1/usage/account?accountId=${accountId}`, getStandardAuthHeaders() )
        .then(function (response) {
          // handle success
          onSuccess(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          onFailure(error);
        })
        .then(function () {
          // always executed
        });
}