import { Page } from "../components/Page"
import React from 'react';
import { Table, Column, Badge } from 'react-rainbow-components';
import { useState } from "react";
import { useEffect } from "react";
import { getCoreVitals, getRequestCountByDay } from "../api/StatsService";
import { Button } from "@mui/material";
import { SimpleChart } from "../components/SimpleChart";
import { StyledCard } from "../components/StyledCard";
import { NumberWithChange } from "../components/NumberWithChange";
import humanize from "humanize-plus";


export const Test = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const [requestsByDay, setRequestsByDay] = useState([{data: []}]);

    const now = new Date();
    now.setDate(now.getDate() - 30);
    

    const [time, setTime] = useState(now.getTime());

    useEffect(() => {
        getCoreVitals(1001, time, (resp) => {setData(resp.data); setLoading(false);}, (fail) => {console.log(fail); setLoading(false);});
        getRequestCountByDay(1001, time, (resp) => {
            const d = resp.data;
            console.log(d);
            const arr = Object.keys(d).map(k => ({x: k, y: [d[k]]}));
            console.log("Arr", arr);
            setRequestsByDay([{name: 'Requests', data: arr}])
        }, (fail) => {});
    }, [time]);

    return (
    <Page name="Overview">
            <div className="rainbow-p-bottom_xx-large">
                <StyledCard>
                    <Table isLoading={loading} data={data} keyField="id" variant="listview">
                        <Column header="Service Name" field="serviceName" />
                        <Column header="Requests" field="requestCount"  component={(c) => <NumberWithChange value={`${humanize.compactInteger(c.value)}`} change={c.row.requestCountChange} useNeutralColors={true}/>} />
                        <Column header="Response Time" field="avgResponseTime" component={(c) => <NumberWithChange value={`${c.value.toFixed(1)} ms`} change={c.row.avgResponseTimeChange.toFixed(1)} invertColors={true} />} />
                        <Column header="Availability" field="availability" component={(c) => <NumberWithChange value={`${c.value.toFixed(1)} %`} change={c.row.availabilityChange.toFixed(1)} />} />
                        <Column header="" component={(c) => <Button style={{width: "100%"}} onClick={() => console.log("Going to traces for ", c.value)}>See Traces</Button>} />
                    </Table>
                </StyledCard>
                <StyledCard style={{height: 250}}>
                    <h1>Request Count</h1>
                    <SimpleChart data={requestsByDay} type="line"/> 
                </StyledCard>
            </div>
    </Page>
    );
}