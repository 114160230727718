import { TextField } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import { attemptLogin, getListOfAccountsUserHasAccessTo } from '../api/UsersService';
import { setUser, setTokens, setBusinessesUserHasAccessTo } from "../api/User";
import { useHistory } from "react-router";
import * as Panelbear from "@panelbear/panelbear-js";
import { Button } from "react-rainbow-components";

export default function Login() {
  // useEffect(() => {
  //   setValues(prefetchValues("portal.earnzqa.com", (v) => {
  //     console.log("New Values: ", v);
  //     setValues(v);
  //     console.log("Label: ", Value.LOGIN_TITLE_LABEL.toString(), v["LOGIN_TITLE_LABEL"])
  //   }));
  // }, []);
  const ENTER_KEY = "Enter";

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let history = useHistory();

  const login = () => {
    setLoading(true);
    setError("");
    setSuccess("");
    Panelbear.track("login.attempt");
    attemptLogin(email, password, (response) => {
      Panelbear.track("login.success");
      let user = response.data;
      setSuccess("Successfully Logged in."); 
      console.log(JSON.stringify(user.headers));
      setUser(user.user);
      setTokens(user.tokens.baseToken, user.tokens.secureToken);
      getListOfAccountsUserHasAccessTo((success) => {
        let accountIds = success.data;
        setBusinessesUserHasAccessTo(accountIds);
        if (accountIds.length > 0) {
          let accountId = accountIds[0];
          history.push(`${accountId}/dashboard`)
        } else {
          // This user has access to no accounts... what do we do in this state.
          setWarning("Successfully logged in, but this user doesn't have access to any accounts.")
        }
        
      }, (apiError) => {
        Panelbear.track("login.fail");
        // ??? Probably send them to some onboarding screen.
        setWarning("Successfully logged in, but failed to find business accounts this user has access to.")
        setError(apiError.response ? apiError.response.data.message : apiError.message)
      })
    }, (apiError) => {setError(apiError.response ? apiError.response.data.message : apiError.message)})
    setLoading(false);
  };

  return(  
  <div style={{width: "100%", height:"1080px"}}>
      <div style={{
      width: "100%", 
      height:"1080px",
      backgroundColor: "#FF3CAC",
      backgroundImage: "linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)",
      padding: "1em 0",
      fontSize: "20px",
      textAlign: "center"
      }}>
        <div style={{color: "white", fontFamily: "Manrope"}}>
      <h1><b>Welcome to</b></h1>
      <h1>Notics Tracer</h1>
      </div>

      <div id="login-inputs" style={{borderRadius: "28px", marginLeft: "37.5%", width: "25%", backgroundColor: "white", padding: "3%"}}>
        <div style={{marginBottom: "2.5%"}}>
        <TextField id="email" variant="outlined" type="email" label="Email" value={email} onChange={(e) => {setEmail(e.target.value)}} />
        </div>
        <div style={{marginBottom: "3%"}}>
        <TextField id="password" variant="outlined" type="password" label="Password" value={password} onKeyPress={(e)=> {if (e.code == ENTER_KEY) {
          login() 
        } 
          }} onChange={(e) => {setPassword(e.target.value)}}/>
        </div>
        <div style={{marginBottom: "3%"}}>
        <Button variant="contained" loading={loading} style={{backgroundColor: "#6A54F3"}} onClick={login}><b>Log in</b></Button>
        </div>
        {error === "" ? <div/> : <Alert severity="error">{error}</Alert>}
        {success === "" ? <div/> : <Alert severity="success">{success}</Alert>}
        {warning === "" ? <div/> : <Alert severity="warning">{warning}</Alert>}
      </div>

  </div>
  </div>
);
}