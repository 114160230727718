import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken, getStandardAuthHeaders } from "./User";

export const getCoreVitals = (accountId, time, onSuccess, onFailure) => {
    axios.get(`${API_HOST}/notics-tracer/v1/stats/core?accountId=${accountId}&time=${time}`, getStandardAuthHeaders() )
        .then(function (response) {
          // handle success
          onSuccess(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          onFailure(error);
        })
        .then(function () {
          // always executed
        });
}

export const getCoreVitalsForService = (accountId, serviceName, start, end, onSuccess, onFailure) => {
  axios.get(`${API_HOST}/notics-tracer/v1/stats/core/${serviceName}?accountId=${accountId}&start=${start}&end=${end}`, getStandardAuthHeaders() )
      .then(function (response) {
        // handle success
        onSuccess(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        onFailure(error);
      })
      .then(function () {
        // always executed
      });
}

export const getRequestCountByDay = (accountId, time, onSuccess, onFailure) => {
  // var d = new Date();
  // d.setDate(d.getDate()-30);
  axios.get(`${API_HOST}/notics-tracer/v1/stats/requestCount?accountId=${accountId}&time=${time}`, getStandardAuthHeaders() )
      .then(function (response) {
        // handle success
        onSuccess(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        onFailure(error);
      })
      .then(function () {
        // always executed
      });
}