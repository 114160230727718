// import logo from './logo.svg';
import './App.css';
// import ReactFlow,  { MiniMap } from 'react-flow-renderer';
import { useEffect, useState } from 'react';
import * as React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Insights from './pages/Insights';

import * as Panelbear from "@panelbear/panelbear-js";
import FunctionRequests from './pages/FunctionRequests';
import { Application } from 'react-rainbow-components';
import { getBackground, getColor } from './components/Theme';
import { Account } from './pages/Account';
import Signup from './pages/Signup';
import Login from './pages/Login';
import { RecentRequests } from './pages/RecentRequests';
import { Dashboard } from './pages/Dashboard';
import { Home } from './pages/Home';
import { Test } from './pages/Test';
import { InsightsV2 } from './pages/InsightsV2';
import { Traces } from './pages/Traces';

export default function App() {

  useEffect(() => {
    // Load the Panelbear tracker once in your app
    Panelbear.load("DrkAbVxBWA0", { debug: false });
  }, []);


Panelbear.trackPageview();
  return (
    <Application theme={{rainbow: {palette: {brand: getColor(), mainBackground: getBackground()}}}}>
      <div style={{
        //background: "white"
        font: 'Roboto',
        background: getBackground(),
        color: getColor(),
        height: "100%",
        clear: "both"
        }}>
        <Router>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            {/* <Navbar /> */}
            <div>
              <Switch>
                <Route path="/:accountId/service/:service/insights">
                  <Insights />
                </Route>
                <Route path="/:accountId/service/:service/function/:requestFunction">
                  <FunctionRequests />
                </Route>
                <Route path="/:accountId/service/:service">
                  <RecentRequests />
                </Route>
                <Route path="/:accountId/account">
                  <Account />
                </Route>
                <Route path="/:accountId/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/signup">
                  <Signup />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/test/:accountId/insights/:service">
                  <InsightsV2 />
                </Route>
                <Route path="/test/:accountId/insights/">
                  <InsightsV2 />
                </Route>
                <Route path="/test/:accountId/traces/:service">
                  <Traces />
                </Route>
                <Route path="/test/:accountId/traces/">
                  <Traces />
                </Route>
                <Route path="/test">
                  <Test />
                </Route>
                <Route path="/">
                  {/* TODO: Add 404 page */}
                  {/* <RecentRequests /> */}
                  <Home />
                </Route>
              </Switch>
            </div>
        </Router>
      </div>
    </Application>
  );
}

    // const [elements, setElements] = useState([
    //   { id: '1', type: 'input', data: { label: 'Node 1' }, position: { x: 250, y: 5 }, isConnectable: false },
    //   // you can also pass a React Node as a label
    //   { id: '2', data: { label: <div>Node 2</div> }, position: { x: 100, y: 100 }, isConnectable: false },
    //   { id: 'e1-2', source: '1', target: '2', animated: true },
    //   { id: '3', data: { label: <div>Node 3</div> }, position: { x: 150, y: 100 }, isConnectable: false },
    //   { id: 'e2-1', source: '2', target: '3', animated: false },
    // ]);

        // Nodes Logic
    // useEffect(() => {
    //   axios.get('http://localhost/v1/ingest/correlationId/7832975d-32c7-44c7-9235-31257bc75583')
    //   .then(function (response) {
    //     // handle success
    //     console.log(response);
    //     let nodes = translateRequestLogsToNodes(response.data);
    //     console.log("LogsToNodes: ", nodes);
    //     setElements(nodes);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  
    // }, []);

// function translateRequestLogsToNodes(requestLogs) {
//   /*
//   [
//   {
//     "id": 29,
//     "correlationId": "29e0a632-d388-41bf-9ebe-31d219b8579f",
//     "callingSource": "null",
//     "serviceName": "notics-tracer-web",
//     "httpMethod": "GET",
//     "url": "http://localhost/v1/acceptance-test/0/1",
//     "requestFunction": "com.tenitx.notics.tracer.service.resources.test.AcceptanceTestResource.getResponse",
//     "responseTime": 105,
//     "responseSize": -1,
//     "statusCode": 200,
//     "timeStart": 1633529591342,
//     "timeEnd": 1633529591447,
//     "depth": 0
//   }
// ]
//   */

//   var nodes = [];

//   let x = 50;
//   let y = 50;

//   requestLogs.forEach(log => {
//     nodes.push(
//       { 
//         id: `${log.serviceName}.${log.depth}`, 
//         type: 'input',
//         data: { label: 
//         <div>
//           <b>{`${log.serviceName}`}</b>
//           <p>{`${log.httpMethod} ${log.url}`}</p>
//         </div> }, 
//         position: { x: x, y: y }, 
//         isConnectable: false 
//       }
//         );

//         if (log.depth > 0) {
//           nodes.push(
//             {
//               id: `${log.callingSource}.${log.depth-1}-${log.serviceName}.${log.depth}`, 
//               source: `${log.callingSource}.${log.depth-1}`, 
//               target: `${log.serviceName}.${log.depth}`, 
//               animated: false 
//           }
//           );
//         }

//         //x+=100;
//         y+=150;
//   });


//   return nodes;
// }
