import { Alert, Button, Snackbar } from "@mui/material";
import { useState } from "react";


export const TokenViewer = (params) => {

    const [toastVisible, setToastVisible] = useState(false);
    const [showToken, setShowToken] = useState(false);

    const { value } = params;


    return (
        <div>
            <div style={{display: "flex"}}>
                <Button onClick={() => setShowToken(!showToken)}>{showToken ? 'Hide' : 'Show'} Token</Button>
                {showToken && <p>{value}</p>}
                {!showToken && <Button onClick={() => copyText(value, () => setToastVisible(true))}>Copy Token</Button>}
            </div>
            <Snackbar
                open={toastVisible}
                autoHideDuration={5000}
                onClose={() => setToastVisible(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Api Token Copied.
                </Alert>
            </Snackbar>
        </div>
    );
}

function copyText(entryText, callback){
    navigator.clipboard.writeText(entryText)
    callback();
}