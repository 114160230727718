import Chart from "react-apexcharts";
import * as React from 'react';

export const SimpleChart = (params) => {
    const {type, data} = params;
    return (
        <Chart options={{
            theme: {
              //mode: isDarkMode() ? "dark" : "light"
            },
            chart: {
              height: "50%",
              type: type,
              stacked: false,
              zoom: {
                  type: 'x',
                  autoScaleYaxis: true
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            // fill: {
            //   type: 'gradient',
            //   gradient: {
            //     opacityFrom: 0.6,
            //     opacityTo: 0.8,
            //   }
            // },
            xaxis: {
              type: 'datetime'
            }
          }} series={data} type="area" height="90%" /> 
    );
}