import React, { useState } from 'react';
import {} from "./style.css";
import Button from '@mui/material/Button';
// import * as LottiePlayer from "@lottiefiles/lottie-player";
import {
  useParams,
  useHistory
} from "react-router-dom";
import ServiceSwitcher from './ServiceSwitcher';
import { getColor, isDarkMode, setIsDarkMode } from '../Theme';
import { CheckboxToggle } from 'react-rainbow-components';


const Navbar = (params) => {

  let { accountId, service } = useParams();
  let history = useHistory();

  let serviceSwitcherEnabled = true;
  
  const [darkMode, setDarkMode] = useState(isDarkMode());
  
  if (params.hasOwnProperty("serviceSwitcherEnabled")) {
    serviceSwitcherEnabled = params.serviceSwitcherEnabled;
  }

    return (
      <div style={{textAlign: "center", height: "75px", fontFamily: "Roboto", color: getColor()}}>
        {/* <img src="https://portal.notics.app/cdn/icon.png" style={{height: "50%"}}/> */}
        {/* <lottie-player
          autoplay
          mode="normal"
          style={{width: "320px"}}
          src="./logo_reveal.json"
        ></lottie-player> */}
      <CheckboxToggle
        label="Dark Mode"
        labelAlignment="left"
        value={darkMode}
        onChange={(() => {
          setDarkMode(!darkMode);
          setIsDarkMode(!darkMode)
        })}
      />
      <React.Fragment key={"Dashboard"}>
          <Button style={{color: getColor()}} onClick={() => history.push(`/${accountId}/dashboard`)}>
            Dashboard
          </Button>
        </React.Fragment>
        <React.Fragment key={"Recent Requests"}>
          <Button style={{color: getColor()}} onClick={() => history.push(`/${accountId}/service/${service}`)}>
            Recent Requests
          </Button>
        </React.Fragment>
        <React.Fragment key={"Insights"}>
          <Button style={{color: getColor()}} onClick={() => history.push(`/${accountId}/service/${service}/insights`)}>
            Insights
          </Button>
        </React.Fragment>
        <React.Fragment key={"Account"}>
          <Button style={{color: getColor()}} onClick={() => history.push(`/${accountId}/account`)}>
            Account
          </Button>
        </React.Fragment>
        {serviceSwitcherEnabled ?  <ServiceSwitcher /> : <div/>}
      </div>
    );
};

export default Navbar;