import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import humanize from 'humanize-plus';

export const NumberWithChange = (params) => {
    const { value, change, invertColors, useNeutralColors } = params;
    console.log("Values: ", value, change)

    const changeDirection = change > 0 ? "UP" : "DOWN";
    let color = changeDirection === "UP" ? ((invertColors || false) ? "RED" : "GREEN") : (invertColors || false) ? "GREEN" : "RED";
    if (useNeutralColors || false) {
        color = "#3F99F4"
    }

    return (
        <div style={{display: "flex"}}>
            <h1>{value}</h1>
            <div style={{color: color, display: "flex"}}>
                {changeDirection === "UP" ? <ArrowUpwardIcon style={{marginTop: "15%", marginLeft: "20%"}} /> : <ArrowDownwardIcon style={{marginTop: "15%", marginLeft: "20%"}}/> }
                <h2>{humanize.compactInteger(change, 1)}%</h2>
            </div>
        </div>
    );
}