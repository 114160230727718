import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import { Application, Sidebar, SidebarItem } from "react-rainbow-components";
import Avatar from '@mui/material/Avatar';
import { useEffect } from "react";
import { getUser } from "../api/User";

export const Header = (params) => {

    const { style, pageName } = params;

    const user = getUser() || {firstName: "", lastName: ""};

    const nameInitals = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`

    const getAvatarBackgroundColor = (stringInput) => {
        let stringUniqueHash = [...stringInput].reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
    }

    return (
        <div style={{
            ...style,
            display: "flex",
            marginTop: "1%"
        }}>
            <h1 style={{fontSize: "24px"}}>{pageName}</h1>
            <div style={{marginLeft: "80%", textAlign: "right", display: "flex"}}>
                <h2 style={{fontSize: "14px", marginTop: "10%", marginRight: "5%"}}>{user.firstName}</h2>
                <Avatar style={{backgroundColor: getAvatarBackgroundColor(nameInitals)}}>
                    <b>{nameInitals}</b>
                </Avatar>
            </div>
           
        
        </div>
    );
}