import { useHistory } from "react-router-dom"
import { getListOfAccountsUserHasAccessTo } from "../api/UsersService"


export const Home = () => {

    let history = useHistory();

    getListOfAccountsUserHasAccessTo((accounts) => {
        if (accounts.data.length > 0) {
            history.push(`/${accounts.data[0]}/dashboard`);
            return;
        }
        history.push('login');
    }, (fail) => history.push('login'));

    return <p style={{height: "100vh"}}>Redirecting...</p>
}