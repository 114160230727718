import { Card } from "@mui/material";

export const StyledCard = (params) => {
    const { style } = params;

    return (
        <Card style={{
            border: "1px solid #DFE0EB",
            background: "#FFFFFF",
            boxSizing: "border-box",
            borderRadius: "8px",
            boxShadow: "5px 5px 10px 3px rgba(0, 0, 0, 0.3)",
            padding: "0.5%",
            margin: "2%",
            ...style
        }} >
            {params.children}
        </Card>
    );

}