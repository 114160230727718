import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";

export const getAvailableServices = async (accountId, onSuccess, onFailure) => {
    try {
     await (axios.get(`${API_HOST}/notics-tracer/v1/reporting/available-services?accountId=${accountId}`,
          {
            headers: {
              tbtc: getBaseToken(),
              tstc: getSecureToken()
            }
          }
        ).then(onSuccess)
        .catch(onFailure)
      );
     } catch(e) {
       console.error(e);
       onFailure(e);
     }
}
