import React from 'react';
import * as axios from 'axios'; // https://github.com/axios/axios
import { useEffect, useState } from 'react';

// Select box
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { host } from "../../env";
import { getBackground, getColor } from '../Theme';

export default function FunctionSwitcher() {

    const [availableFunctions, setAvailableFunctions] = useState([]);

    let location = useLocation();
  
    let { service, requestFunction } = useParams();
  
    if (!service) {
      service = "business-management-api";
    }
  
    const history = useHistory();
  
    useEffect(() => {
      axios.get(`${host}/v1/reporting/${service}/available-functions`)
      .then(function (response) {
        // handle success
        console.log("Setting Available functions to :", response.data)
        setAvailableFunctions(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  
    }, []);
  
    const functionsList = availableFunctions.map(service => {
      return( <MenuItem value={service} key={service}>{service}</MenuItem> );
    });

    return (
        <div style={{width: "fit-content", blockSize: "fit-content", background: getBackground()}}>
            <Box sx={{ width: "25%", marginLeft: "2%", background: getBackground(), color: getColor() }}>
                <FormControl fullWidth margin="margin" variant="standard" size="small">
                    <Select
                        labelId="function-select"
                        id="function-select"
                        value={requestFunction}
                        label="Function"
                        onChange={(event) => history.push(location.pathname.replace(requestFunction, event.target.value))}
                        style={{background: getBackground(), color: getColor()}} 
                        >
                        {functionsList}
                    </Select>
                </FormControl>
            </Box>
        </div>
    );
}