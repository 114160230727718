import { Page } from "../components/Page"
import React from 'react';
import { Table, Column, Badge, DateTimePicker, Accordion, AccordionSection, TableWithBrowserPagination } from 'react-rainbow-components';
import { useState } from "react";
import { useEffect } from "react";
import { getCoreVitals, getCoreVitalsForService, getRequestCountByDay } from "../api/StatsService";
import { Button } from "@mui/material";
import { SimpleChart } from "../components/SimpleChart";
import { StyledCard } from "../components/StyledCard";
import { NumberWithChange } from "../components/NumberWithChange";
import humanize from "humanize-plus";
import { useQuery } from "../utils/QueryParams";
import { useParams } from "react-router-dom";
import DataCard from "../components/DataCard";
import ServiceSwitcher from "../components/navbar/ServiceSwitcher";
import Timeline from "../components/Timeline";
import { getErrorRequestsForService } from "../api/TracesService";


export const InsightsV2 = () => {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    const [insights, setInsights] = useState({});

    const [start, setStart] = useState(new Date(Date.now()-604800000));
    const [end, setEnd] = useState(new Date());
    const [errorTraces, setErrorTraces] = useState([]);
    const [selectedErrorTrace, setSelectedErrorTrace] = useState({});

    // const query = useQuery();
    // console.log("Service: ", query.get("service"));

    const {accountId, service} =  useParams();
    
    const [requestsByDay, setRequestsByDay] = useState([{data: []}]);

    const now = new Date();
    now.setDate(now.getDate() - 30);
    

    const [time, setTime] = useState(now.getTime());

    useEffect(() => {
        setData();
        if (service === undefined) {
            return;
        }
        getCoreVitalsForService(accountId, service, start.getTime(), end.getTime(), (resp) => {setData(resp.data); setLoading(false);}, (fail) => {console.log(fail); setLoading(false);});
        getRequestCountByDay(1001, time, (resp) => {
            const d = resp.data;
            console.log(d);
            const arr = Object.keys(d).map(k => ({x: k, y: [d[k]]}));
            console.log("Arr", arr);
            setRequestsByDay([{name: 'Requests', data: arr}])
        }, (fail) => {});
        getErrorRequestsForService(accountId, service, start.getTime(), end.getTime(), (resp) => setErrorTraces(resp.data), (fail) => {});
    }, [service, start, end]);

    const TraceGraph = () => {
        if (Object.keys(selectedErrorTrace) === 0) {
            return <div/>;
        }
        return <Timeline input={selectedErrorTrace}/>
    }

    return (
    <Page name="Overview">
            <div className="rainbow-p-bottom_xx-large">
                <StyledCard><ServiceSwitcher /></StyledCard>
                <StyledCard>
                <div style={{display: "flex", width: "50%", marginLeft: "25%", marginBottom: "2.5%"}} >
                    <DateTimePicker 
                    label="Start"
                    value={start}
                    onChange={setStart}
                    maxDate={end}
                    style={{marginRight: "2%"}}
                    />
                    <DateTimePicker 
                    label="End"
                    value={end}
                    onChange={setEnd}
                    minDate={start}
                    />
                </div>
                </StyledCard>
                <StyledCard>
                <div style={{display: "flex", marginLeft: "12.5%", width: "75%", justifyContent: "center", flexDirection: "row"}}>
                    <DataCard value={(data ? data.avgResponseTime : 0).toFixed(0)} label="ms" name="Avg Reponse Time" />
                    <DataCard value={data ? data.requestCount : 0} label="" name="Requests" />
                    <DataCard value={(data ? data.availability : 0 || 0).toFixed(2)} label="%" name="Availability" />
                </div>
                </StyledCard>
                <StyledCard>
                <Accordion id="accordion-1">
                    <AccordionSection label="Errors">
                        <TableWithBrowserPagination pageSize={10} isLoading={loading} data={Object.keys(errorTraces).map(k => sortByKey(errorTraces[k], "depth")[0])} keyField="correlationId" variant="listview">
                            {/* <Column header="Correlation Id" field="correlationId" /> */}
                            <Column cellAlignment="left" header="Timestamp" field="timeStart" component={(c) => (<h1>{new Date(c.value).toLocaleString()}</h1>)} />
                            <Column header="Function" field="requestFunction" component={(c) => <h1>{c.value.substring(c.value.lastIndexOf('.') + 1)}</h1>} />
                            <Column defaultWidth="75" header="Method" field="httpMethod" />
                            <Column header="Calling Source" field="callingSource" />
                            <Column defaultWidth="100" header="Time" field="responseTime" component={(c) => <h1>{c.value} ms</h1>} />
                            <Column defaultWidth="75" header="Status" field="statusCode" />
                            <Column defaultWidth="200" header="" field="correlationId" component={(c) => (selectedErrorTrace.length > 0 ? selectedErrorTrace[0].correlationId : "") === c.value ? <div /> :<Button style={{width: "100%"}} onClick={() => setSelectedErrorTrace(errorTraces[c.value])}>Show Trace</Button>} /> 
                        </TableWithBrowserPagination>
                        <TraceGraph />
                    </AccordionSection>
                </Accordion>
                </StyledCard>
            </div>
    </Page>
    );
}

function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}